/* eslint-disable camelcase */
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/analytics';

/* Log environment */
let env = process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : process.env.NODE_ENV;
console.log('ENVIRONMENT: ', env);

let firebaseConfig = {};

/* Connect to firebase project test (includes demo) */
if (env === 'development' || env === 'test' || env === 'demo') {
	firebaseConfig = {
		apiKey: 'AIzaSyAxu0p9XXkQ7V1CFVWhsaCj_FHDel8bcWI',
		authDomain: 'cgl-karriereindsatsen-u-test.firebaseapp.com',
		projectId: 'cgl-karriereindsatsen-u-test',
		storageBucket: 'cgl-karriereindsatsen-u-test.appspot.com',
		messagingSenderId: '684741657119',
		appId: '1:684741657119:web:3151a2d9244824f74f4b2a'
	};
}

/* Connect to firebase: production */
if (env === 'production') {
	firebaseConfig = {
		apiKey: 'AIzaSyDYcoPTCJOv9-RNRhzhFnrDcUNCL9y-bRc',
		authDomain: 'cgl-karriereindsatsen-u-prod.firebaseapp.com',
		projectId: 'cgl-karriereindsatsen-u-prod',
		storageBucket: 'cgl-karriereindsatsen-u-prod.appspot.com',
		messagingSenderId: '717584031580',
		appId: '1:717584031580:web:a75d6223add8105f1f6b99'
	};
}

/* Initialize firebase */
firebase.initializeApp(firebaseConfig);

export default firebase.firestore;