import AreaHub from 'components/area-hub/area-hub';
import Area from 'components/area-hub/area';
import Case from 'components/area-hub/case';

const gamePagesData = {
	areaHub: {
		component: AreaHub,
		background: 'office',
		infoTitle: 'Velkommen',
		infoText: `Velkommen til Nova Medical.
		<br />I er virksomhedens nye ledelse - deres nye dream team - og jeres opgave er at sikre, at virksomheden er effektiv og bæredygtig.
		<br />Derfor skal I nu besøge virksomhedens forskellige afdelinger og hjælpe dem med at løse problemer og træffe beslutninger. 
		<br />I kan starte i lige den afdeling, I vil. 
		<br />Hver afdeling har en række cases, som hver rummer forskellige opgaver. 
		<br />Prøv at se, hvor mange opgaver, I kan nå. Men husk at være grundige. 
		<br />Jo færre fejl I laver, jo flere point får I. 
		<br /><br />Held og lykke!`,

		routes: [
			{
				id: 'research',
				name: 'research & development',
				pageId: 'areaResearch'
			},
			{
				id: 'it',
				name: 'it',
				pageId: 'areaIt'
			},
			{
				id: 'build',
				name: 'byggeri',
				pageId: 'areaBuild'
			},
			{
				id: 'prep',
				name: 'beredskab',
				pageId: 'areaPrep'
			},
			{
				id: 'design',
				name: 'design & kommunikation',
				pageId: 'areaDesign'
			},
			{
				id: 'production',
				name: 'produktion og robotteknologi',
				pageId: 'areaProduction'
			}
		]
	},
	areaResearch: {
		component: Area,
		caseComponent: Case,
		id: 'research',
		name: 'research & development',
		background: 'research',
		infoTitle: 'Research & development',
		infoText: `Udvikling og optimering af virksomhedens produkter.`,
		routes: [
			{
				id: 1,
				title: 'Case 1',
				subtitle: 'Uheld',
				pageId: null,
				text: `Afdelingen er ved at undersøge en række enzymer, som blev opbevaret i forskellige plastbeholdere. Det viser sig, at der er hul i en af beholderne, og det kræver hurtig handling fra jeres side.`,
			},
			{
				id: 2,
				title: 'Case 2',
				subtitle: 'Enzym X',
				pageId: null,
				text: 'Det viser sig, at det nye enzym - som får kodenavnet Enzym X - kan nedbryde plastik. Det giver uanede muligheder og kan være noget af et scoop for Nova Medicals produktudvikling.'
			},
			{
				id: 3,
				title: 'Case 3',
				subtitle: 'Ny udvikling',
				pageId: null,
				text: `R&D-afdelingen udvikler hele tiden nye produkter eller varianter af de eksisterende. Målet er altid at få nye lægemidler på markedet, men det er en lang og tung proces.`
			},
			{
				id: 4,
				title: 'Case 4',
				subtitle: 'Ny medicin',
				pageId: null,
				text: `I er blevet færdige med at udvikle en ny medicin, som kan nedsætte feber hos børn og unge. Spørgsmålet er, hvad der skal ske nu?`
			},
			{
				id: 5,
				title: 'Case 5',
				subtitle: 'Medicins udvikling',
				pageId: null,
				text: `Nova Medical prøver hele tiden at udvikle ny medicin, men ved du, hvad man gjorde, før man havde moderne medicin? Man behandlede ud fra teorien om "de fire kropsvæsker".`
			}
		] 
	},
	areaIt: {
		component: Area,
		caseComponent: Case,
		id: 'it',
		background: 'it',
		infoTitle: 'IT',
		infoText: `Virksomheden har en stor gruppe udviklere ansat. De udvikler og vedligeholder organisationens intranet og hjemmeside, men de udvikler også egne programmer til hele virksomheden.`,
		routes: [
			{
				id: 1,
				title: 'Case 1',
				subtitle: 'Introduktion',
				pageId: null,
				text: 'IT-afdelingen arbejder blandet andet med kodning til eksisterende systemer. Derfor handler første case om grundlæggende programmering og arbejdsmetoder. '
			},
			{
				id: 2,
				title: 'Case 2',
				subtitle: 'WiFi-problemer',
				pageId: null,
				text: 'Wifi-routeren i kommunikationsafdelingen virker ikke.'
			},
			{
				id: 3,
				title: 'Case 3',
				subtitle: '',
				pageId: null,
				text: 'GDPR er et fællesregelsæt for håndtering af privatpersoners data i EU. Men ved du egentlig, hvad det betyder?'
			}
		]
	},
	areaBuild: {
		component: Area,
		caseComponent: Case,
		id: 'build',
		background: 'build',
		infoTitle: 'Byggeri',
		infoText: `Virksomheden har en byggetjeneste. De står for udvikling og vedligeholdelse af bygninger.`,
		routes: [
			{
				id: 1,
				title: 'Case 1',
				subtitle: 'Ny tank',
				pageId: null,
				text: 'Det er besluttet, at der skal placeres en ny tank i produktionshallen. Den skal have tre gange større kapacitet, og det giver en række udfordringer.'
			},
			{
				id: 2,
				title: 'Case 2',
				subtitle: 'Vedligeholdelse',
				pageId: null,
				text: 'Byggeafdelingen står også for at vedligeholde bygninger og udstyr.'
			}
		]
	},

	areaPrep: {
		component: Area,
		caseComponent: Case,
		id: 'prep',
		background: 'prep',
		infoTitle: 'Beredskab',
		infoText: `Virksomheden har både et beredskabskorps samt en masse sikkerhedsinstrukser, som træder i kraft i nødstilfælde.`,
		routes: [
			{
				id: 1,
				title: 'Case 1',
				subtitle: 'Introduktion',
				pageId: null,
				text: 'Hos Nova Medical har vi med med både maskiner, kemi og teknik at gøre. Derfor er sikkerhed ekstremt vigtigt. Første case handler om grundlæggende sikkerhed.'
			},
			{
				id: 2,
				title: 'Case 2',
				subtitle: 'Brand',
				pageId: null,
				text: 'Nova Medical har stor fokus på brandsikkerhed. Det handler blandt andet om at uddanne personalet, så de ved, hvad de skal gøre i tilfælde af brand.'
			}
		] 
	},
	areaDesign: {
		component: Area,
		caseComponent: Case,
		id: 'design',
		background: 'design',
		infoTitle: 'Design & kommunikation',
		infoText: `Virksomhedens kommunikationsafdeling står for reklamer af produktet samt intern kommunikation og design af produktemballage.`,
		routes: [
			{
				id: 1,
				title: 'Case 1',
				subtitle: 'Visuel stil',
				pageId: null,
				text: `En ny type insulin er netop blevet lanceret. Der skal vælges et grafisk udtryk for varedeklaration bag på produktet.`,
				files: [
					{
						id: 'design-guide',
						type: 'image',
						btnText: 'Se designguide',
					}
				]
			},
			{
				id: 2,
				title: 'Case 2',
				subtitle: 'Salg og Marketing',
				pageId: null,
				text: 'Afdelingen står også for markedsføring. Nova Medical sælger især til offentlige institutioner og private, og det giver nogle særskilte udfordringer.'
			},
			{
				id: 3,
				title: 'Case 3',
				subtitle: 'Ny indpakning',
				pageId: null,
				text: 'Der skal laves et nyt design til en indpakning.'
			},
			{
				id: 4,
				title: 'Case 4',
				subtitle: 'Reklame og oplysning',
				pageId: null,
				text: 'Lægemidlet er nu klar til at komme ud på markedet, men det er vigtigt, at Nova Medical overholder de gældende regler på området.'
			},
			{
				id: 5,
				title: 'Case 5',
				subtitle: 'Website',
				pageId: null,
				text: 'I skal vælge designet til Nova Medicals hjemmeside.'
			}
		] 
	},
	areaProduction: {
		component: Area,
		caseComponent: Case,
		id: 'production',
		background: 'production',
		infoTitle: 'Produktionen',
		infoText: `Virksomheden har produktion af enzymer. Det foregår i et moderne, industrielt laboratorium, og produktionen kræver en del robotteknologi.`,
		routes: [
			{
				id: 1,
				title: 'Case 1',
				subtitle: 'Klargøring',
				pageId: null,
				text: `Når man arbejder med enzymer, arbejder man med kemi, og her er både viden, erfaring, udstyr og testning vigtig. Og frem for alt er det vigtigt, at alting er klar, før produktionen går i gang. `
			},
			{
				id: 2,
				title: 'Case 2',
				subtitle: 'Enzymproduktion',
				pageId: null,
				text: 'Produktion af enzymer kræver, at mange faktorer går op i en højere enhed. Og der skal konstant måles og justeres, så alting fungerer optimalt.'
			},
			{
				id: 3,
				title: 'Case 3',
				subtitle: 'Ny teknologi',
				pageId: null,
				text: 'I overvejer at investere mere i robotteknologi til produktionen. Noget af det vil I selv kunne udvikle, men der er mange opgaver foran jer, før I kan lykkes med det.'
			},
			{
				id: 4,
				title: 'Case 4',
				subtitle: 'Valg af robotter',
				pageId: null,
				text: 'Et af de vigtigste spørgsmål er, hvor I skal satse på robotteknologi. Nogle områder er bare mere egnede til det end andre.'
			},
			{
				id: 5,
				title: 'Case 5',
				subtitle: 'Programmering',
				pageId: null,
				text: 'Ikke alle robotter er fysiske robotter. Nogle robotter er software som skal programmeres. I har haft udfordringer med et af de robotprogrammer, I bruger i produktionen'
			},
			{
				id: 6,
				title: 'Case 6',
				subtitle: 'Robotter',
				pageId: null,
				text: 'Nova Medical ønsker at benytte robotteknologi i fremtiden. Ledelsen skal dog have forståelse for den nye måde at tænke produktionsprocessen på.'
			},
			{
				id: 7,
				title: 'Case 7',
				subtitle: '',
				pageId: null,
				text: 'Robotter kan mange ting, men hvad ved du egentlig om menneskekroppen?'
			}
		]
	},

};

export {
	gamePagesData,
};